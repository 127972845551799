<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        v-if="config.showSidebar"
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column show"
      >
        <div
          class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
        >
          <div class="px-2 py-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              aria-controls="sidebar-add-new-event"
              :aria-expanded="String(true)"
              variant="primary"
              size="sm"
              block
              @click="newEvent()"
            >
              <feather-icon icon="PlusIcon" /> Toevoegen
            </b-button>
            <div class="mt-3">
              <h5 class="app-label section-label mb-1">
                <span class="align-middle">Kalenders</span>
              </h5>
              <b-form-checkbox
                v-model="checkAll"
                @change="selectAll"
                :indeterminate="indeterminate"
                class="mb-1"
              >
                Alle kalenders
              </b-form-checkbox>
              <b-form-group>
                <b-form-checkbox-group
                  v-model="selectedCalendarList"
                  name="event-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="item in calendarList"
                    :key="item.id"
                    name="event-filter"
                    :value="item"
                    class="mb-1"
                  >
                    <div
                      class="rounded-circle d-inline-block mx-25"
                      :style="`background-color: ${item.bgColor}`"
                      style="height: 10px; width: 10px"
                    />
                    {{ item.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row px-3 py-1 d-flex justify-content-between">
          <b-button-group v-if="config.showViewPicker" size="sm">
            <b-button variant="primary" @click="setView('day')">Dag</b-button>
            <b-button variant="primary" @click="setView('week')">Week</b-button>
            <b-button variant="primary" @click="setView('month')"
              >Maand</b-button
            >
          </b-button-group>
          <b-button
            v-if="config.showTodayBtn"
            size="sm"
            variant="primary"
            @click="calAction('today')"
            >Vandaag</b-button
          >
          <b-button-group v-if="config.showCalNav" size="sm">
            <b-button variant="primary" @click="calAction('prev')"
              ><feather-icon icon="ChevronLeftIcon"
            /></b-button>
            <b-button variant="primary">{{ dateRange }}</b-button>
            <b-button variant="primary" @click="calAction('next')"
              ><feather-icon icon="ChevronRightIcon"
            /></b-button>
          </b-button-group>
        </div>
        <!-- Calendar -->
        <div class="row">
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <calendar
                  ref="cal"
                  style="height: 700px"
                  :view="selectedView"
                  :week="week"
                  :month="month"
                  :template="template"
                  :calendars="selectedCalendarList"
                  :schedules="schedules"
                  :taskView="false"
                  :usageStatistics="false"
                  :theme="theme"
                  :useCreationPopup="useCreationPopup"
                  :useDetailPopup="useDetailPopup"
                  @beforeUpdateSchedule="beforeUpdateSchedule"
                  @beforeCreateSchedule="beforeCreateSchedule"
                  @clickSchedule="onClickSchedule"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="modal-event"
      v-model="eventDialogVisible"
      id="modal-event"
      :title="
        editActivity && editActivity.id ? editActivity.name : 'Nieuw evenement'
      "
    >
      <b-overlay :show="formLoading" rounded="sm">
        <b-form @submit.stop.prevent="submitForm">
          <b-row>
            <!-- first name -->
            <b-col cols="12">
              <b-form-group label="Naam activiteit" label-for="v-name">
                <b-form-input
                  id="v-name"
                  :disabled="formLoading || editActivity.cancelled"
                  v-model="editActivity.name"
                  :class="$v.editActivity.name.$error ? 'is-invalid' : ''"
                  placeholder="Naam"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Groepen" label-for="v-groups">
                <v-select
                  id="v-groups"
                  v-model="editActivity.groupIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :disabled="formLoading || editActivity.cancelled"
                  :closeOnSelect="false"
                  :class="$v.editActivity.groupIds.$error ? 'is-invalid' : ''"
                  label="name"
                  :reduce="(item) => item.id"
                  :options="$store.state.groups"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Start" label-for="v-start">
                <flat-pickr
                ref="startDatePicker"
                  class="form-control"
                  id="v-start"
                  :disabled="formLoading || editActivity.cancelled"
                  :config="configdateTimePicker"
                  v-model="cloneStart"
                  placeholder="Start"
                  @input="onStartInput"
                  :class="$v.editActivity.start.$error ? 'is-invalid' : ''"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Einde" label-for="v-end">
                <flat-pickr
                ref="endDatePicker"
                  class="form-control"
                  id="v-end"
                  :disabled="formLoading || editActivity.cancelled"
                  :config="configdateTimePicker"
                  v-model="cloneEnd"
                  placeholder="Einde"
                  @input="onEndInput"
                  :class="$v.editActivity.end.$error ? 'is-invalid' : ''"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
      <template #modal-footer>
        <b-col cols="12" class="p-0">
          <div class="d-flex justify-content-between">
            <div>
              <b-dropdown
                v-if="editActivity.id"
                :disabled="formLoading || editActivity.cancelled"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                text="Acties"
                variant="flat-primary"
              >
                <b-dropdown-item variant="warning" @click="cancelEvent">
                  Evenement annuleren
                </b-dropdown-item>
                <b-dropdown-item variant="danger" @click="deleteEvent">
                  Evenement verwijderen
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="d-flex">
              <b-button
                :disabled="formLoading || editActivity.cancelled"
                variant="outline-secondary"
                class="mr-1"
                @click="eventDialogVisible = false"
              >
                Annuleren
              </b-button>
              <b-button
                :disabled="formLoading || editActivity.cancelled"
                variant="primary"
                @click="submitForm"
              >
                {{ editActivity && editActivity.id ? "Bewerken" : "Toevoegen" }}
              </b-button>
            </div>
          </div>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { db } from "@/firebase/firebaseConfig";
import "tui-calendar/dist/tui-calendar.css";
import { Calendar } from "@toast-ui/vue-calendar";

import { required } from "vuelidate/lib/validators";

import { Dutch } from "flatpickr/dist/l10n/nl.js";

import {
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  BCol,
  BRow,
  BOverlay,
  VBModal,
} from "bootstrap-vue";

import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";

import flatPickr from "vue-flatpickr-component";

import moment from "moment";

export default {
  name: "Kalender",
  directives: { "b-modal": VBModal, Ripple },
  components: {
    calendar: Calendar,
    flatPickr,
    BButton,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    BCol,
    BRow,
    vSelect,
    BOverlay,
  },
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          showSidebar: true,
          showViewPicker: true,
          showTodayBtn: true,
          showCalNav: true,
          groupIds: null
        };
      },
    },
  },
  data() {
    return {
      formLoading: false,
      schedules: [],
      activities: [],
      unwatch1: null,
      unwatch2: null,
      eventDialogVisible: false,
      showDetail: false,
      editActivity: {},
      deleting: false,
      calendarList: [],
      checkAll: true,
      indeterminate: false,
      selectedCalendarList: [],
      configdateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: "d/m/Y -  H:i",
        time_24hr: true,
        locale: Dutch,
      },
      theme: {
        "common.border": "1px solid #e1e2ee",
        "common.today.color": "#e6277a",
        "week.today.color": "#e1e2ee",
        "week.currentTime.color": "#e6277a",
        "week.currentTimeLinePast.border": "1px dashed #e6277a",
        "week.currentTimeLineBullet.backgroundColor": "#e6277a",
        "week.currentTimeLineToday.border": "1px solid #e6277a",
      },
      dateRange: "",
      selectedView: "month",
      selectedDate: new Date(),
      loading: true,
      activeName: "all",
      useCreationPopup: false,
      useDetailPopup: false,
      selectedShedule: null,
      cloneStart: null,
      cloneEnd: null,
      week: {
        startDayOfWeek: 1,
        daynames: ["Zo", "Ma", "Di", "Woe", "Do", "Vrij", "Za"],
        hourStart: 6,
        hourEnd: 24,
      },
      month: {
        startDayOfWeek: 1,
        daynames: ["Zo", "Ma", "Di", "Woe", "Do", "Vrij", "Za"],
      },
      template: {
        timegridDisplayPrimaryTime: function (time) {
          return moment(time).format("HH:mm");
        },
        alldayTitle: function () {
          return '<span class="tui-full-calendar-left-content">Hele dag</span>';
        },
      },
    };
  },
  watch: {
    selectedCalendarList(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.checkAll = false;
      } else if (newValue.length === this.$store.state.groups.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else {
        this.indeterminate = true;
        this.checkAll = false;
      }

      const { invoke } = this.$refs.cal;

      this.calendarList.forEach((calendar) => {
        var c =
          this.selectedCalendarList.filter((el) => el.id == calendar.id)
            .length > 0;
        invoke("toggleSchedules", calendar.id, !c);
      });
    },
  },
  created() {
    if (this.$store.state.organisation) {
      this.loadCurrentRange();
    }

    this.unwatch1 = this.$store.watch(
      (state) => state.organisation,
      (oldValue, newValue) => {
        if (this.$store.state.organisation) {
          this.loadCurrentRange();
        }
      }
    );

    if (this.$store.state.groups) {
      this.setCalendars();
    }

    this.unwatch2 = this.$store.watch(
      (state) => state.groups,
      (oldValue, newValue) => {
        if (newValue) {
          this.setCalendars();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch1();
    this.unwatch2();
  },
  mounted() {
    this.setRenderRangeText();
  },
  methods: {
    selectAll(checked) {
      this.selectedCalendarList = checked ? this.calendarList : [];
    },
    onStartInput(e){
      this.editActivity.start = new Date(e);
    },
    onEndInput(e){
      this.editActivity.end = new Date(e);
    },
    submitForm(e) {
      e.preventDefault();
      if (!this.$v.$invalid) {
        this.editEvent();
      } else {
        this.$v.$touch();
      }
    },
    newEvent() {
      this.beforeCreateSchedule({
        start: moment(Date.now()),
        end: moment(Date.now()).add(2, "hours"),
      });
    },
    async beforeCreateSchedule(res) {
      this.editActivity = {
        name: "",
        start: res.start.toDate(),
        end: res.end.toDate(),
        groupIds: [],
      };
      this.cloneStart = JSON.parse(JSON.stringify(this.editActivity.start));
      this.cloneEnd = JSON.parse(JSON.stringify(this.editActivity.end));
      this.eventDialogVisible = true;
    },
    startEditActivity(a) {
      this.editActivity = { ...{}, ...a };
      this.cloneStart = JSON.parse(JSON.stringify(this.editActivity.start));
      this.cloneEnd = JSON.parse(JSON.stringify(this.editActivity.end));
      this.eventDialogVisible = true;
      this.showDetail = false;
    },
    onClickSchedule(e) {
      this.selectedShedule = this.activities.filter(
        (a) => a.id == e.schedule.id
      )[0];
      this.startEditActivity(this.selectedShedule);
    },
    onEventEdited(event) {
      this.formLoading = false;
      this.eventDialogVisible = false;
      this.editActivity = {};
      this.loadCurrentRange();
    },
    editEvent() {
      this.formLoading = true;
      this.editActivity.organisation = this.$store.state.organisation.id;
      this.editActivity.organisationName = this.$store.state.organisation.shortName;
      this.editActivity.groupNames = [];
      this.editActivity.groupIds.forEach((id, index) => {
        var i = this.$store.state.groups.findIndex(item => item.id == id);
        this.editActivity.groupNames.push(this.$store.state.groups[i].name);
      });
      if (this.editActivity.id) {
        this.$store
          .dispatch("calendar/updateEvent", this.editActivity)
          .then(() => {
            this.onEventEdited();
          });
      } else {
        this.editActivity.cancelled = false;
        this.editActivity.attRequired = false;
        this.editActivity.author = {
          displayName: this.$store.state.AppActiveUser.displayName,
          uid: this.$store.state.AppActiveUser.uid,
          type: this.$store.state.AppActiveUser.organisations[
            this.$store.state.organisation.id
          ].type,
          uoid: this.$store.state.AppActiveUser.organisations[
            this.$store.state.organisation.id
          ].uoid,
        };
        this.editActivity.recurring = {
          endDate: null,
          frequency: "never",
        };
        this.$store
          .dispatch("calendar/addEvent", this.editActivity)
          .then((res) => {
            this.onEventEdited();
          });
      }
    },
    cancelEvent() {
      this.$store
        .dispatch("calendar/cancelEvent", this.editActivity)
        .then(() => {
          this.onEventEdited();
        });
    },
    async beforeUpdateSchedule(obj) {
      var event = {
        ...{},
        ...this.activities.filter((a) => a.id == obj.schedule.id)[0],
      };
      event.start = obj.start.toDate();
      event.end = obj.end.toDate();
      this.$store.dispatch("calendar/updateEvent", event).then(() => {
        var s = [...[], ...this.schedules];
        var i = s.findIndex((o) => o.id == obj.schedule.id);
        s[i].start = obj.start;
        s[i].end = obj.end;
        this.schedules = s;
      });
    },
    async deleteEvent(s) {
      this.$bvModal
        .msgBoxConfirm(
          `Je staat op het punt ${this.editActivity.name} te verwijderen. Dit kan niet ongedaan gemaakt worden.`,
          {
            title: "Ben je zeker?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Verwijderen",
            cancelTitle: "Annuleren",
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("calendar/removeEvent", this.editActivity)
              .then(() => {
                this.onEventEdited();
              });
          }
        });
    },
    setView(view) {
      this.selectedView = view;
      this.loadCurrentRange();
      this.setRenderRangeText(view);
    },

    setCalendars() {
      if (this.config.groupIds && this.config.groupIds.length > 0) {
        this.config.groupIds.forEach((id) => {
          var i = this.$store.state.groups.findIndex((item) => item.id == id);
          var g = this.$store.state.groups[i];
          var c = {
            id: g.id,
            name: g.name,
            color: "#FFFFFF",
            bgColor: g.color || "#1f2185",
            dragBgColor: g.color || "#1f2185",
            borderColor: g.color || "#1f2185",
          };
          this.calendarList.push(c);
          this.selectedCalendarList.push(c);
          setTimeout(() => this.setView("month"), 1000);
        });
      } else {
        this.calendarList = [];
        Object.keys(this.$store.state.groups).forEach((k) => {
          var g = this.$store.state.groups[k];
          var c = {
            id: g.id,
            name: g.name,
            color: "#FFFFFF",
            bgColor: g.color || "#1f2185",
            dragBgColor: g.color || "#1f2185",
            borderColor: g.color || "#1f2185",
          };
          this.calendarList.push(c);
          this.selectedCalendarList.push(c);
        });
      }
    },
    loadCurrentRange() {
      setTimeout(() => {
        const { invoke } = this.$refs.cal;
        if (invoke) {
          const rangeStart = invoke("getDateRangeStart").toDate();
          const rangeEnd = invoke("getDateRangeEnd").toDate();
          this.$store
            .dispatch("calendar/fetchEventsForRange", {
              orgId: this.$store.state.organisation.id,
              start: rangeStart,
              end: rangeEnd,
              groupIds: this.config.groupIds || null
            })
            .then((res) => {
              this.schedules = res.schedules;
              this.activities = res.activities;
            });
        } else {
          this.loadCurrentRange();
        }
      }, 100);
    },
    calAction(action) {
      this.$refs.cal.invoke(action);
      this.loadCurrentRange();
      this.setRenderRangeText();
    },
    setRenderRangeText(view) {
      setTimeout(() => {
        const { invoke } = this.$refs.cal;
        var v = view;
        if (!v) {
          v = invoke("getViewName");
        }
        const calDate = moment(invoke("getDate").toDate());
        const rangeStart = moment(invoke("getDateRangeStart").toDate());
        const rangeEnd = moment(invoke("getDateRangeEnd").toDate());
        var dateRangeText = "";
        switch (v) {
          case "month":
            dateRangeText = `${rangeStart.add(14, "days").format("MMMM YYYY")}`;
            break;
          case "week":
            dateRangeText = `${rangeStart.format("D")}-${rangeEnd.format(
              "D MMMM YYYY"
            )}`;
            break;
          case "day":
            dateRangeText = `${rangeStart.format("D MMMM YYYY")}`;
            break;
          default:
            dateRangeText = `${rangeStart.format("MMMM YYYY")}`;
        }
        this.dateRange = dateRangeText;
      }, 100);
    },
  },
  validations: {
    editActivity: {
      name: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      groupIds: {
        required,
      },
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>